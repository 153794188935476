.filter-selector-item {
  margin: 16px 0 0; }
  .filter-selector-item .filter-selector-item__btn {
    position: relative;
    padding: 0 26px;
    width: 100%;
    min-height: 56px;
    line-height: 56px;
    color: #696969;
    text-align: left;
    background: #f6f6f6;
    display: flex;
    align-items: center; }
    @media (min-width: 769px) {
      .filter-selector-item .filter-selector-item__btn {
        position: unset;
        padding: 16px 22px 16px 24px;
        width: auto;
        height: auto;
        line-height: 1;
        text-align: center;
        border-radius: 5px;
        background-color: #ffffff;
        border: none;
        white-space: nowrap;
        margin-bottom: 5px; } }
    .filter-selector-item .filter-selector-item__btn .filter-selector-icon {
      margin: 0 0 0 10px; }
  .filter-selector-item .filter-selector-item__btn::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 24px;
    transform: translateY(-50%);
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 6px 7px 0 7px;
    border-color: #1C21DF transparent transparent transparent; }
    @media (min-width: 769px) {
      .filter-selector-item .filter-selector-item__btn::after {
        display: none; } }
  .filter-selector-item .filter-selector-item__btn.is-active::after {
    border-color: #ffffff transparent transparent transparent; }
  .filter-selector-item .filter-selector-item__btn.is-active {
    color: #1C21DF;
    background-color: transparent;
    border: 1px solid #1C21DF; }
  @media (min-width: 769px) {
    .filter-selector-item {
      margin: 0 13px 0 0; } }

.filter-selector-item__overlay {
  display: none; }
  @media (min-width: 769px) {
    .filter-selector-item__overlay {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: block;
      background: rgba(26, 40, 44, 0.3);
      z-index: 20; } }

.filter-selector-item:last-child {
  margin-right: 0; }

.filter-selector-item__dropdown {
  display: none; }
  @media (min-width: 769px) {
    .filter-selector-item__dropdown {
      position: absolute;
      top: 56px;
      border-radius: 20px;
      border: solid 1px #F7F7F3;
      box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);
      width: 312px;
      background: #F7F7F3;
      padding: 24px 0 5px 0;
      z-index: 1; } }

.filter-selector-item__dropdown.is-visible {
  display: block;
  z-index: 6; }

.filter-selector-item__dropdown.modal {
  display: none;
  position: relative;
  top: unset;
  left: unset;
  max-height: unset;
  max-width: unset;
  transform: none;
  padding: 0; }
  @media (max-width: 1023.98px) {
    .filter-selector-item__dropdown.modal {
      top: 50%;
      max-width: 60vw; } }
  @media (min-width: 769px) {
    .filter-selector-item__dropdown.modal {
      position: fixed;
      top: 55%;
      left: 50%;
      transform: translate(-50%, -50%);
      flex-direction: column;
      width: 655px;
      max-width: 40vw;
      max-height: 75vh;
      border-radius: 30px;
      padding: 24px 0 20px; } }
  @media (min-width: 769px) {
    .filter-selector-item__dropdown.modal {
      max-width: 60vw;
      top: 50%; } }
  .filter-selector-item__dropdown.modal .filter-selector-item__dropdown-bottom {
    padding: 15px 32px 0; }
  .filter-selector-item__dropdown.modal .filter-selector-item__dropdown-content {
    padding-top: 0; }

.filter-selector-item__modal-heading {
  display: none; }
  @media (min-width: 769px) {
    .filter-selector-item__modal-heading {
      display: flex;
      padding: 0 37px 0px;
      border-bottom: solid 1px #f6f6f6; } }

@media (min-width: 769px) {
  .filter-selector-item__modal-title,
  .filter-selector-item__modal-close-btn {
    margin-left: auto; } }

.filter-selector-item__modal-content {
  display: flex;
  flex-direction: column;
  padding: 0 24px; }
  @media (min-width: 769px) {
    .filter-selector-item__modal-content {
      padding: 0 32px; } }

.filter-selector-item__modal-section {
  margin-top: 22px; }
  @media (min-width: 769px) {
    .filter-selector-item__modal-section {
      margin-top: 30px; } }

@media (min-width: 769px) {
  .filter-selector-item__modal-section .filter-selector-item:last-child {
    margin-bottom: 30px; } }

.filter-selector-item__modal-section.wide {
  display: flex;
  justify-content: space-between;
  align-items: center; }
  @media (max-width: 768.98px) {
    .filter-selector-item__modal-section.wide {
      flex-direction: column;
      align-items: flex-start; } }

.filter-selector-item__modal-section-title {
  margin-bottom: 16px; }

.filter-selector-item__modal-section-subtitle {
  display: block;
  margin: 0 0 16px; }
  @media (min-width: 769px) {
    .filter-selector-item__modal-section-subtitle {
      margin: unset; } }

.filter-selector-item__modal-section-divider {
  margin-bottom: 25px; }

.filter-selector-item__modal-checkbox-wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 20px; }
  @media (max-width: 1023.98px) {
    .filter-selector-item__modal-checkbox-wrapper {
      grid-template-columns: repeat(1, 1fr); } }
  @media (max-width: 768.98px) {
    .filter-selector-item__modal-checkbox-wrapper {
      grid-template-columns: 1fr; } }

.filter-selector-item__dropdown-option-wrapper {
  padding: 11px 0px 5px 0px; }

.filter-selector-item__dropdown-title {
  margin-bottom: 30px; }

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  -moz-appearance: none;
  border: 2.3px solid #1E5558;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  box-shadow: -2px 2px 0 0 #1E5558;
  cursor: pointer;
  background: white;
  margin-top: -8px; }

input[type="range"]::-moz-range-thumb {
  -webkit-appearance: none;
  -moz-appearance: none;
  border: 2.3px solid #1E5558;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  box-shadow: -2px 2px 0 0 #1E5558;
  cursor: pointer;
  background: white;
  margin-top: -8px; }

input[type="range"]::-ms-thumb {
  -webkit-appearance: none;
  -moz-appearance: none;
  border: 2.3px solid #1E5558;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  box-shadow: -2px 2px 0 0 #1E5558;
  cursor: pointer;
  background: white;
  margin-top: -8px; }

.filter-selector-item__dropdown-range-selector {
  width: 100%;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  height: 4px;
  background: #1E5558;
  border-radius: 5px; }
  .filter-selector-item__dropdown-range-selector .filter-selector-item::-webkit-slider-thumb,
  .filter-selector-item__dropdown-range-selector .filter-selector-item::-moz-range-thumb,
  .filter-selector-item__dropdown-range-selector .filter-selector-item::-ms-thumb {
    -webkit-appearance: none;
    -moz-appearance: none;
    border: 2.3px solid #1C21DF;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    box-shadow: -2px 2px 0 0 #1C21DF;
    cursor: pointer;
    background: white;
    margin-top: -14px; }
  .filter-selector-item__dropdown-range-selector .filter-selector-item::-webkit-slider-runnable-track,
  .filter-selector-item__dropdown-range-selector .filter-selector-item::-moz-range-track {
    height: 1px;
    cursor: pointer;
    background: #3071a9;
    border-radius: 20px;
    border: 0px solid #f6f6f6; }

.filter-selector-item__dropdown-range-amount {
  display: block;
  margin-top: 19px; }

.filter-selector-item__dropdown-content {
  width: 100%;
  padding: 0px 0 12px;
  overflow: auto;
  max-height: 250px; }
  .filter-selector-item__dropdown-content .Select-By-Date {
    margin-left: 20px;
    margin-right: 20px; }
  .filter-selector-item__dropdown-content.expand-modal {
    min-height: 300px;
    overflow-y: scroll; }
  .filter-selector-item__dropdown-content.MoreFiltersModal {
    max-height: 550px; }

.filter-selector-item__dropdown-option {
  padding: 8px 24px; }

.option-checkbox {
  display: flex;
  align-items: center;
  cursor: pointer; }
  .option-checkbox .scholarships-amount {
    margin-left: auto;
    border-radius: 4px;
    background: #f6f6f6;
    padding: 4px 12px; }
  .option-checkbox .filter-selector-item:hover {
    cursor: pointer; }
  .option-checkbox .checkbox__input {
    display: grid;
    grid-template-areas: "checkbox"; }
    .option-checkbox .checkbox__input > * {
      grid-area: checkbox; }
    .option-checkbox .checkbox__input input[type="checkbox"] + .checkbox__control {
      border-radius: 4px;
      background: #ffffff; }
    .option-checkbox .checkbox__input input[type="radio"] + .checkbox__control {
      border-radius: 20px;
      background: #ffffff; }
      .option-checkbox .checkbox__input input[type="radio"] + .checkbox__control svg {
        border-radius: 20px !important; }
    .option-checkbox .checkbox__input input {
      opacity: 0;
      width: 20px;
      height: 20px;
      cursor: pointer; }
      .option-checkbox .checkbox__input input:hover + .checkbox__control {
        color: #1C21DF;
        border: 1px solid #1E5558;
        cursor: pointer; }
      .option-checkbox .checkbox__input input:focus + .checkbox__control {
        color: #1C21DF;
        border: 1px solid #1E5558;
        cursor: pointer; }
      .option-checkbox .checkbox__input input:checked + .checkbox__control svg {
        transform: scale(1);
        background: #1C21DF;
        color: #ffffff;
        border-radius: 3px;
        border: 2px solid #1C21DF;
        margin-left: -2px;
        margin-top: -2px; }
      .option-checkbox .checkbox__input input:disabled + .checkbox__control {
        color: #ffffff;
        background: #979797; }
    .option-checkbox .checkbox__input .checkbox__control {
      display: inline-grid;
      width: 20px;
      height: 20px;
      border-radius: 4px;
      border: 1px solid #1E5558;
      cursor: pointer; }
      .option-checkbox .checkbox__input .checkbox__control svg {
        transition: transform 0.1s ease-in 25ms;
        transform-origin: bottom left; }

.checkbox-circle {
  display: inline-block;
  -webkit-border-radius: 4px;
  /* Safari 3-4, iOS 1-3.2, Android 1.6- */
  -moz-border-radius: 4px;
  /* Firefox 1-3.6 */
  border-radius: 4px;
  /* Opera 10.5, IE 9, Safari 5, Chrome, Firefox 4, iOS 4, Android 2.1+ */
  width: 20px;
  height: 20px;
  border: 1px solid #1E5558;
  margin-right: 18px;
  cursor: pointer; }
  .checkbox-circle .filter-selector-item:checked {
    background-color: #1C21DF; }

.filter-selector-item__dropdown-bottom {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 15px 20px;
  border-top: solid 1px #f6f6f6; }
  @media (min-width: 769px) {
    .filter-selector-item__dropdown-bottom {
      margin: 0px 0 0;
      padding: 15px 20px 15px; } }
  .filter-selector-item__dropdown-bottom .filter-selector-item__clear-btn {
    margin-right: 10px; }
  .filter-selector-item__dropdown-bottom .CTA {
    padding: 8px 20px; }

.filter-selector-item__save-btn {
  padding: 7px 12px 9px 15px;
  border-radius: 4px;
  color: #ffffff;
  background: #1C21DF; }
  .filter-selector-item__save-btn:hover {
    transform: scale(1.04);
    background-color: #171BB5; }

.filter-selector-item__dropdown.is-visible {
  display: block; }

.filter-selector-item__dropdown.is-visible.modal {
  display: flex; }

.filter-selector__items.is-active .filter-selector-item .filter-selector-item__btn.mobile-common-dropdown-down-arrow::after {
  transform: rotate(0deg); }

.filter-selector__items.is-active .filter-selector-item .filter-selector-item__btn .filter-selector-icon {
  display: none; }

#scholarship-launch-popup {
  max-height: 72vh; }
  #scholarship-launch-popup .react-datepicker__month-dropdown {
    padding-left: 35px;
    text-align: left; }
  #scholarship-launch-popup .react-datepicker__month-option {
    font-size: 14px !important;
    line-height: normal !important; }
  #scholarship-launch-popup .react-datepicker__day, #scholarship-launch-popup .react-datepicker__day-name {
    width: 1.2rem !important;
    height: 1.2rem !important;
    line-height: 1.2rem;
    font-size: 12px;
    margin: 0.2rem; }
  #scholarship-launch-popup .react-datepicker__year-read-view {
    font-size: 14px !important;
    line-height: 14px !important; }
  #scholarship-launch-popup .react-datepicker__month-read-view--selected-month {
    font-size: 14px !important;
    line-height: 14px !important; }
  #scholarship-launch-popup .react-datepicker__month-read-view--down-arrow {
    top: 11px; }
  #scholarship-launch-popup .react-datepicker__year-read-view--down-arrow {
    top: 3px; }
  #scholarship-launch-popup .react-datepicker__navigation {
    top: 8px; }
  #scholarship-launch-popup .react-datepicker__current-month {
    font-size: 14px !important;
    line-height: 14px !important; }
  #scholarship-launch-popup .react-datepicker__day--keyboard-selected {
    border-radius: 0rem !important; }
  #scholarship-launch-popup #task-modal-content {
    height: auto; }
    #scholarship-launch-popup #task-modal-content .task-head_container .event-head {
      cursor: pointer; }
    #scholarship-launch-popup #task-modal-content .user-task-add__form .applicant-task__submit {
      display: flex;
      align-items: center;
      justify-content: center; }
      #scholarship-launch-popup #task-modal-content .user-task-add__form .applicant-task__submit #task-page__cta__cancel {
        margin-right: 10px; }
