.slider-button {
  position: relative;
  display: inline-block;
  width: 80px;
  height: 40px; }
  .slider-button input {
    opacity: 0;
    width: 0;
    height: 0; }
  .slider-button .yes, .slider-button .no {
    pointer-events: none;
    text-transform: uppercase;
    position: absolute;
    top: 12px; }
  .slider-button .yes {
    left: 14px; }
  .slider-button .no {
    right: 14px; }

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #f6f6f6;
  transition: .4s;
  border-radius: 34px; }

.slider:before {
  position: absolute;
  content: "";
  height: 32px;
  width: 32px;
  left: 4px;
  bottom: 4px;
  background-color: #737373;
  transition: .4s;
  border-radius: 50%;
  z-index: 2; }

input:checked + .slider {
  background-color: rgba(30, 85, 88, 0.2); }

input:focus + .slider {
  box-shadow: 0 0 5px 2px rgba(30, 85, 88, 0.5);
  outline: 1px solid #1E5558; }

input:checked + .slider:before {
  transform: translateX(40px);
  background-color: #1E5558; }
