.state-selector {
  position: relative; }
  .state-selector.multi-state-selector-dropdown .filter-selector-item__dropdown.modal {
    position: absolute;
    top: 52px;
    left: unset;
    transform: unset;
    width: 255px;
    padding: 20px 0px 20px 0px;
    right: 0; }
    .state-selector.multi-state-selector-dropdown .filter-selector-item__dropdown.modal .option-checkbox .checkbox-label {
      cursor: pointer; }
  .state-selector.multi-state-selector-dropdown .filter-selector-item__dropdown.is-visible.modal {
    display: unset; }
  .state-selector.multi-state-selector-dropdown .filter-selector-item__dropdown .filter-selector-item__dropdown-content {
    height: 250px;
    padding: unset; }
    .state-selector.multi-state-selector-dropdown .filter-selector-item__dropdown .filter-selector-item__dropdown-content div[class$="-menu"] {
      width: calc(100% - 6px);
      margin-left: 3px; }
    .state-selector.multi-state-selector-dropdown .filter-selector-item__dropdown .filter-selector-item__dropdown-content .field {
      margin-bottom: 0; }
  @media (max-width: 768.98px) {
    .state-selector {
      flex: 1;
      width: 100%; } }
  .state-selector__dropdown {
    position: absolute;
    background: white;
    width: 100%;
    border-radius: 20px;
    left: 0;
    top: 42px;
    box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);
    z-index: 21; }
  .state-selector__dropdown-option {
    display: block;
    padding: 10px 24px;
    cursor: pointer; }
  .state-selector__label {
    margin-right: 8px; }
  .state-selector__globe-icon {
    margin-right: 6px; }
  .state-selector__btn {
    display: flex;
    align-items: center; }
    @media (max-width: 768.98px) {
      .state-selector__btn {
        flex-direction: row-reverse; }
        .state-selector__btn .state-selector__arrow-down-icon {
          margin-left: 20px; } }
  .state-selector__select {
    -webkit-appearance: none;
    -moz-appearance: none;
    border: none;
    width: 100%;
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%23008067'%3E%3Cg%3E%3Cpath d='M5 9L12.029 15 19 9z' transform='translate(-1366 -28) translate(1366 28)'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E") no-repeat;
    background-position-x: 95%;
    background-position-y: 57%; }
