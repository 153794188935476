.H1DesktopGreen {
  font-family: 'Inter-Light';
  font-size: 36px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  color: #1E5558; }

.H1DesktopBlack {
  font-family: 'Inter-Light';
  font-size: 36px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  color: #1a282c; }

.H1DesktopWhite {
  font-family: 'Inter-Light';
  font-size: 36px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  color: #f6f6f6; }

.H2Desktop {
  font-family: 'Inter-Medium';
  font-size: 32px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal; }

.H2DesktopGreen {
  font-family: 'Inter-Medium';
  font-size: 32px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.11;
  letter-spacing: normal;
  color: #1E5558; }

.DesktopGreen {
  font-family: 'Inter-Medium';
  font-size: 18px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: normal;
  color: #1E5558; }

.H2DesktopWhite {
  font-family: 'Inter-Medium';
  font-size: 32px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  color: #ffffff; }
  @media (max-width: 768.98px) {
    .H2DesktopWhite {
      font-size: 30px;
      line-height: 1.33; } }

.H3Desktop {
  font-family: 'Inter-Medium';
  font-size: 29px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal; }
  @media (max-width: 768.98px) {
    .H3Desktop {
      font-size: 24px;
      line-height: 2; } }

.H3BlackDesktop {
  font-family: 'Inter-Medium';
  font-size: 29px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1a282c; }

.H3WhiteDesktop {
  font-family: 'Inter-Medium';
  font-size: 29px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #ffffff; }

.H4DesktopGreen {
  font-family: 'Inter-Medium';
  font-size: 26px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1E5558; }

.H5DesktopGreen {
  font-family: 'Inter-Medium';
  font-size: 23px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1E5558; }

.H5DesktopGrey {
  font-family: 'Inter-Medium';
  font-size: 23px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #979797; }

.H5DesktopBlack {
  font-family: 'Inter-Medium';
  font-size: 23px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1a282c; }

.H6DesktopBlack {
  font-family: 'Inter-Medium';
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1a282c; }

.H4DesktopWhite {
  font-family: 'Inter-Medium';
  font-size: 26px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff; }

.H4DesktopBlack {
  font-family: 'Inter-Medium';
  font-size: 26px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1a282c; }

.H6Desktop {
  font-family: 'Inter-Medium';
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal; }

.H6DesktopGrey {
  font-family: 'Inter-Medium';
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #979797; }

.H6DesktopBlack {
  font-family: 'Inter-Medium';
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1a282c; }

.H6DesktopGreen {
  font-family: 'Inter-Medium';
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1E5558; }

.PDesktopGreen {
  font-family: 'Inter-Medium';
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: black; }

.H6DesktopWhite {
  font-family: 'Inter-Medium';
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #ffffff; }

.BodyLargeWhite {
  font-family: 'Inter-Medium';
  font-size: 18px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: normal;
  color: #ffffff; }

.BodyDefault {
  font-family: 'Inter-Medium';
  font-size: 16px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal; }

.BodyDefaultBold {
  font-family: 'Inter-Bold';
  font-size: 16px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal; }

.BodyDefaultBoldGrey {
  font-family: 'Inter-Bold';
  font-size: 16px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #979797; }

.BodyDefaultBoldWhite {
  font-family: 'Inter-Bold';
  font-size: 16px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #ffffff; }

.BodyDefaultBoldBlack {
  font-family: 'Inter-Medium';
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1a282c; }

.BodyDefaultRegularGrey {
  font-family: 'Inter-Medium';
  font-size: 16px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #979797; }

.BodyDefaultRegularBlack, .donation-forms-custom-tabs .donation-forms-custom-tabs-button {
  font-family: 'Inter-Regular';
  font-size: 16px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1a282c; }

.BodySmallMediumGrey {
  font-family: 'Inter-Medium';
  font-size: 14px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #979797; }

.BodySmallMediumWhite {
  font-family: 'Inter-Medium';
  font-size: 14px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #ffffff; }

.BodySmallMediumBlack {
  font-family: 'Inter-Medium';
  font-size: 14px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1a282c; }

.BodySmallMedium {
  font-family: 'Inter-Medium';
  font-size: 14px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal; }

.CTA {
  font-family: 'Inter-Medium';
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal; }

.CTABlue {
  font-family: 'Inter-Medium';
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1C21DF; }

.CTAGrey {
  font-family: 'Inter-Medium';
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1a282c; }

.CTAGreen {
  font-family: 'Inter-Medium';
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1E5558; }

.CTABlack {
  font-family: 'Inter-Medium';
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1a282c; }

.CTARed {
  font-family: 'Inter-Medium';
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #FF542D; }

.CTAWhite {
  font-family: 'Inter-Medium';
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #ffffff; }

.CTAYellow {
  font-family: 'Inter-Medium';
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #ffcd00; }

.BodySmallRegular {
  font-family: 'Inter-Medium';
  font-size: 14px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal; }

.BodySmallRegularWhite {
  font-family: 'Inter-Medium';
  font-size: 14px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #ffffff; }

.BodySmallRegularGrey {
  font-family: 'Inter-Medium';
  font-size: 14px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #737373; }

.BodySmallRegularGreen {
  font-family: 'Inter-Medium';
  font-size: 14px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1E5558; }

.BodySmallRegularBlack {
  font-family: 'Inter-Medium';
  font-size: 14px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1a282c; }

.LabelGrey {
  font-family: 'Inter-Medium';
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #737373; }

.LabelWhite {
  font-family: 'Inter-Medium';
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #ffffff; }

.EyebrowBlack {
  font-family: 'Inter-Medium';
  font-size: 10px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1a282c;
  text-transform: uppercase; }

.EyebrowWhite {
  font-family: 'Inter-Medium';
  font-size: 10px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #ffffff;
  text-transform: uppercase; }

.EyebrowGreen {
  font-family: 'Inter-Medium';
  font-size: 10px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1E5558;
  text-transform: uppercase; }

.EyebrowGrey {
  font-family: 'Inter-Medium';
  font-size: 10px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #737373;
  text-transform: uppercase; }

.CTA-Anchor-Tag-css {
  display: flex;
  justify-content: center;
  width: 150px;
  height: 50px;
  color: #ffffff;
  background-color: #1C21DF;
  border: 1px solid #1C21DF;
  letter-spacing: normal;
  font-family: 'Inter-Medium', sans-serif;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  transition: transform 0.25s;
  border-radius: 5px;
  cursor: pointer; }
  .CTA-Anchor-Tag-css__Text {
    margin: auto;
    text-align: center;
    display: flex;
    align-items: center; }
    .CTA-Anchor-Tag-css__Text svg {
      margin-right: 12px;
      transform: rotate(180deg); }

.filter-selector {
  display: flex;
  flex-direction: column; }
  .filter-selector__global-search-btn .field__text-field {
    margin-top: 0px; }
  .filter-selector__global-search-btn.mobile-search-scholarship-container {
    width: 100%; }
    .filter-selector__global-search-btn.mobile-search-scholarship-container .mobile-search-input {
      margin-bottom: 10px; }
      .filter-selector__global-search-btn.mobile-search-scholarship-container .mobile-search-input .field__text-field {
        background: #ffffff;
        border-radius: 5px;
        height: 58px; }
  .filter-selector__global-search-btn__global-search-textbox {
    margin-bottom: 0px; }

.filter-selector__heading {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px; }

.filter-selector__title {
  text-transform: capitalize; }

.filter-selector__items {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: none;
  background-color: white; }
  @media (min-width: 769px) {
    .filter-selector__items {
      position: unset;
      top: unset;
      right: unset;
      bottom: unset;
      left: unset;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: transparent; } }
  @media (max-width: 768.98px) {
    .filter-selector__items {
      position: unset;
      margin-top: 10px !important;
      top: unset;
      right: unset;
      bottom: unset;
      left: unset; } }

.filter-selector__items.is-active {
  display: flex;
  flex-direction: column; }
  @media (min-width: 769px) {
    .filter-selector__items.is-active {
      flex-direction: unset; } }

.filter-selector__dropdown {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-radius: 5px; }
  @media (min-width: 769px) {
    .filter-selector__dropdown {
      display: none; } }

.filter-selector__globe-icon {
  margin-left: 16px;
  margin-right: 8px; }

.filter-selector__state-option {
  display: flex;
  align-items: center;
  width: 100%; }

.filter-selector__btn {
  float: right;
  padding: 15px 23.5px 15px 20px;
  border-left: solid 1px #f0f2f2;
  border-radius: 0 28px 28px 0; }

.filter-selector__btn.is-active {
  background-color: #1E5558; }

.filter-selector__btn.is-active path {
  stroke: #ffffff; }

.filter-selector__top-bar {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  border-bottom: solid 1px #f0f2f2; }
  @media (min-width: 769px) {
    .filter-selector__top-bar {
      display: none; } }

.filter-selector__mobile-modal {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 20; }

.filter-selector__content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100% - 136px);
  padding: 47px 20px 23.5px; }

.filter-selector__results-btn {
  display: block;
  max-width: 100%;
  margin: 24px;
  padding: 20px 0;
  border-radius: 5px; }
  @media (min-width: 769px) {
    .filter-selector__results-btn {
      display: none; } }

.filter-selector__clear-btn {
  border-bottom: solid 1px #1E5558; }

.filter-selector__option-btn {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center; }

.filter-selector__option-label {
  display: inline-block;
  color: #008291;
  padding: 16.5px 0 19.5px; }

.filter-selector__option-arrow {
  transform: rotate(270deg); }

.filter-selector__leading {
  flex: 1 1 100%;
  margin: 47px 0 0;
  padding: 0 20px;
  overflow-y: scroll;
  min-height: 72px;
  align-items: center; }
  @media (min-width: 769px) {
    .filter-selector__leading {
      display: flex;
      flex: unset;
      margin: unset;
      padding: unset;
      overflow-y: unset; } }

.filter-selector__trailing {
  display: none; }
  .filter-selector__trailing .filter-selector__global-search-container .field {
    margin-bottom: 0px; }
  @media (min-width: 769px) {
    .filter-selector__trailing {
      display: block; } }
  .filter-selector__trailing .filter-selector__global-search-container .filter-selector__global-search-btn {
    position: relative; }
    .filter-selector__trailing .filter-selector__global-search-container .filter-selector__global-search-btn .field__text-field {
      width: 280px; }

.filter-selector__global-search-icon {
  fill: #1E5558; }

.filter-selector__recommended-dropdown .field {
  margin-bottom: 0px;
  width: 250px; }

body {
  overflow: auto; }

.filter-selector.applicant-home__filter.hideFilterLeftSide {
  justify-content: space-between;
  margin-bottom: 10px; }
  .filter-selector.applicant-home__filter.hideFilterLeftSide .filter-selector__items .filter-selector__trailing {
    width: 100%;
    display: flex;
    flex-direction: row-reverse; }

.donation-forms-custom-tabs {
  display: flex;
  align-items: center;
  margin-bottom: 25px; }
  @media (max-width: 768.98px) {
    .donation-forms-custom-tabs {
      margin-top: 15px;
      justify-content: center; } }
  .donation-forms-custom-tabs .donation-forms-custom-tabs-Disply {
    margin-right: 10px; }
  .donation-forms-custom-tabs .donation-forms-custom-tabs-button {
    border: 1px solid #ccc;
    padding: 10px;
    cursor: pointer;
    border-radius: 5px;
    margin-right: 10px; }
    @media (max-width: 768.98px) {
      .donation-forms-custom-tabs .donation-forms-custom-tabs-button {
        margin-top: 15px;
        font-size: 15px; } }
    .donation-forms-custom-tabs .donation-forms-custom-tabs-button.active {
      background: #cceeff; }
      .donation-forms-custom-tabs .donation-forms-custom-tabs-button.active .label {
        padding-bottom: 5px; }
    .donation-forms-custom-tabs .donation-forms-custom-tabs-button.Inactive .label {
      padding-bottom: 5px; }

.filter-account-select {
  display: flex;
  justify-content: space-between;
  align-items: center; }

.marketplace-account-selector .program-sponsors.applicant-accounts-dropdown {
  margin-left: 0px !important;
  margin-right: 0px !important; }
  .marketplace-account-selector .program-sponsors.applicant-accounts-dropdown .field.field-group__field-full {
    margin-bottom: 10px;
    width: 280px !important; }
    .marketplace-account-selector .program-sponsors.applicant-accounts-dropdown .field.field-group__field-full div[class$="-SelectContainer"] {
      width: 280px; }

.programtabs {
  display: flex;
  padding-left: 20px;
  margin-top: 0px !important;
  align-items: center; }
  @media (max-width: 479.98px) {
    .programtabs {
      padding-left: 0px;
      font-size: 13px !important;
      margin-bottom: 15px;
      display: unset; }
      .programtabs .BodyDefaultRegularBlack, .programtabs .donation-forms-custom-tabs .donation-forms-custom-tabs-button, .donation-forms-custom-tabs .programtabs .donation-forms-custom-tabs-button {
        display: none; } }
  @media (min-width: 480px) {
    .programtabs {
      font-size: 14px; } }
  .programtabs .BodyDefaultRegularBlack, .programtabs .donation-forms-custom-tabs .donation-forms-custom-tabs-button, .donation-forms-custom-tabs .programtabs .donation-forms-custom-tabs-button {
    margin-right: 20px; }

.programtab {
  border: 1px solid #ccc;
  padding: 10px;
  cursor: pointer;
  border-radius: 5px;
  margin-right: 10px; }
  .programtab.active {
    background: #cceeff; }
  @media (max-width: 479.98px) {
    .programtab {
      padding: 10px 5px 10px 5px;
      margin-right: 5px;
      margin-bottom: 5px; } }

.programtab.active {
  background-color: #cceeff !important; }

.public-marketplace-heading {
  font-size: 32px; }
  .public-marketplace-heading .marketplace {
    font-style: italic;
    font-weight: 600; }

.public-marketplace-heading-text {
  max-width: 47rem;
  font-size: '14px';
  max-width: '620px' !important;
  margin-top: 10px !important;
  font-family: 'Inter-Medium';
  font-size: 16px !important;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1a282c !important;
  text-transform: none; }

.show-more-filter-container {
  display: flex;
  align-items: center; }
  .show-more-filter-container.active {
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 0px 10px;
    background: #cceeff; }
  .show-more-filter-container .show-hide-toggle-filter {
    display: flex;
    align-items: center;
    line-height: 17px; }
    .show-more-filter-container .show-hide-toggle-filter .show-more-filter-icon {
      margin-left: 10px; }

.filter-and-account-search-program-container {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 20px; }
  @media (max-width: 768.98px) {
    .filter-and-account-search-program-container {
      display: unset; } }
  @media (min-width: 480px) {
    .filter-and-account-search-program-container {
      font-size: 14px;
      margin-bottom: 20px; } }
  .filter-and-account-search-program-container .account-search-program-container .program-sponsors-container {
    max-width: 300px; }
    .filter-and-account-search-program-container .account-search-program-container .program-sponsors-container .program-sponsors .field.field-group__field-full {
      margin-bottom: 5px; }
      .filter-and-account-search-program-container .account-search-program-container .program-sponsors-container .program-sponsors .field.field-group__field-full div[class$="-Control"] {
        min-height: 35px;
        padding: 0 10px; }
  .filter-and-account-search-program-container .account-search-program-container .search-program-container {
    max-width: 300px; }
    .filter-and-account-search-program-container .account-search-program-container .search-program-container .filter-selector__global-search-btn.applicant-search .field {
      margin-bottom: 5px; }
      .filter-and-account-search-program-container .account-search-program-container .search-program-container .filter-selector__global-search-btn.applicant-search .field .field__text-field {
        height: 45px;
        padding: 0 15px; }
